import { useChannelPricing } from '.';
import {
  GqlPurchaseStatus,
  GqlPurchaseType,
} from '../apollo/generated/graphql';
import { AccessAction } from '../types/enums';
import { useChannel } from './useChannel';
import { useWorker } from './useWorker';

export const useChannelAccessAction = (): AccessAction => {
  const channel = useChannel();
  const {
    pricingPlans: { hasAccessToChannelByPricingPlan },
  } = useWorker();
  const purchases = channel.purchases.entities;
  const { isPricingPlanChannel, channelSubscription } = useChannelPricing();

  if (isPricingPlanChannel && !hasAccessToChannelByPricingPlan) {
    return AccessAction.PricingPlan;
  }

  if (channelSubscription) {
    const hasSubscription = purchases.find(
      (purchase) =>
        (purchase.type === GqlPurchaseType.Subscription &&
          purchase.status === GqlPurchaseStatus.Active) ||
        purchase.status === GqlPurchaseStatus.Canceled,
    );

    if (!hasSubscription) {
      return AccessAction.Subscribe;
    }
  }

  return AccessAction.None;
};
