import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { PlaybackComponentProps } from '../../Player.types';
import s from './Facebook.scss';
import { FacebookPlayer } from './Facebook.types';

const win = typeof window !== 'undefined' ? (window as any) : undefined;

const apiPromise = new Promise<void>((resolve) => {
  if (win && !win.FB) {
    const script = document.createElement('script');
    script.src =
      'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
    document.body.appendChild(script);
    win.fbAsyncInit = resolve;
  }
});

type Size = {
  width: number;
  height: number;
};

export const Facebook: FC<PlaybackComponentProps> = ({
  src,
  playing,
  onEnd,
  onPlay,
  onPause,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size | null>(null);
  const [player, setPlayer] = useState<FacebookPlayer>();

  const setAllowAttribute = useCallback(() => {
    const container = ref.current;

    if (container) {
      const iframe = container.querySelector('iframe');

      if (iframe) {
        const allows = iframe.allow.split(' ').map((i) => i.trim());

        if (!allows.includes('autoplay')) {
          allows.push('autoplay');
          iframe.setAttribute('allow', allows.join(';'));
          iframe.setAttribute('src', iframe.src);
        }
      }
    }
  }, [ref]);

  const handleReady = useCallback(
    (message: any) => {
      if (message.type === 'video' && message.id === src) {
        const instance: FacebookPlayer = message.instance;
        setPlayer(instance);

        instance.subscribe('startedPlaying', onPlay);
        instance.subscribe('paused', onPause);
        instance.subscribe('finishedPlaying', onEnd);
      }
    },
    [src, onPlay, onPause, onEnd],
  );

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      const height = Math.ceil(entry.contentRect.height);
      const width = Math.ceil((height / 9) * 16);

      setSize({ width, height });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  useEffect(() => {
    if (size) {
      apiPromise.then(() => {
        win.FB.Event.subscribe('xfbml.ready', handleReady);
        win.FB.Event.subscribe('iframeplugin:create', setAllowAttribute);

        win.FB.init({
          xfbml: true,
          version: 'v3.2',
        });
      });
    }
  }, [size, setAllowAttribute, handleReady]);

  useEffect(() => {
    if (player && playing) {
      player.play();
    }
  }, [player, playing]);

  return (
    <div ref={ref} className={s.facebook}>
      <div
        className={classNames('fb-video', s.video)}
        id={src}
        data-href={src}
        data-height={size?.height}
        data-width={size?.width}
        data-show-text="false"
        data-allowfullscreen="true"
      />
    </div>
  );
};
