import React, { FC, useCallback, useMemo, MouseEvent } from 'react';
import {
  Picture,
  PictureMode,
} from '@wix/wix-vod-shared/dist/src/common/components/picture';
import { VideoAspectRatio } from '../../../../../common/components/VideoAspectRatio/VideoAspectRatio';
import { GqlVideoFragment } from '../../../../../apollo/generated/graphql';
import s from './Thumbnail.scss';
import {
  usePlayback,
  useSelectedVideo,
  useVideoPricing,
  useVideoSource,
} from '../../../../../hooks';
import { ThumbnailPlayButton } from '../../../../../common/components/ThumbnailPlayButton/ThumbnailPlayButton';
import { useDuration } from '../../../../../hooks/useDuration';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { SHOW_INFO_VALUES } from '@wix/wix-vod-constants/dist/app-settings/show-info-values';
import classNames from 'classnames';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../../stylesParams';
import { useVideoPlayButton } from '../../../../../hooks/useVideoPlayButton';
import CalendarSmallIcon from 'wix-ui-icons-common/on-stage/CalendarSmall';
import { LiveLabel } from '../../../../../common/components/LiveLabel/LiveLabel';

type Props = {
  video: GqlVideoFragment;
};

const useViewSettings = () => {
  const styles = useStyles();
  return {
    showTitle: styles.get(stylesParams.showVideoTitleInList),
  };
};

const LeftCorner: FC<Props> = ({ video }) => {
  const { isStreamingLive, isScheduledLive } = useVideoSource(video);
  const duration = useDuration(video.content?.duration);

  if (isStreamingLive) {
    return <LiveLabel size={12} />;
  }

  if (isScheduledLive) {
    return <CalendarSmallIcon />;
  }

  return <span>{duration}</span>;
};

export const Thumbnail: FC<Props> = ({ video }) => {
  const { selectVideo } = useSelectedVideo();
  const { pause, play } = usePlayback();
  const { canPlay } = useVideoPlayButton(video);
  const styles = useStyles();
  const { currency } = useVideoPricing(video);
  const { showTitle } = useViewSettings();
  const src = video.cover.selected;
  const { isStreamingLive } = useVideoSource(video);

  const showInfoAction = useMemo(
    () => styles.get(stylesParams.showInfoAction),
    [styles],
  );

  const handleOverlayClick = useCallback(() => {
    selectVideo(video);
    pause();
  }, [pause, selectVideo, video]);

  const handlePlayClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      selectVideo(video);
      play();

      // prevent calling handleOverlayClick
      event.stopPropagation();
    },
    [play, selectVideo, video],
  );

  return (
    <div className={s.root} onClick={handleOverlayClick}>
      <VideoAspectRatio>
        {src && <Picture src={src} mode={PictureMode.CONTAIN} />}
        <div
          className={classNames(s.overlay, {
            [s.showOverlayOnHover]:
              showInfoAction === SHOW_INFO_VALUES.ON_MOUSE_OVER,
            [s.live]: isStreamingLive,
          })}
        >
          {showTitle ? <div className={s.header}>{video.title}</div> : null}
          {canPlay && (
            <div className={s.center}>
              <div onClick={handlePlayClick}>
                <ThumbnailPlayButton />
              </div>
            </div>
          )}
          <span className={s.leftCorner}>
            <LeftCorner video={video} />
          </span>
          {currency && (
            <span className={s.currency}>
              <CurrencySign currency={currency as any} />
            </span>
          )}
        </div>
      </VideoAspectRatio>
    </div>
  );
};
