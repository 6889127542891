import React, { FC } from 'react';
import PlayButtonSmall from 'wix-ui-icons-common/on-stage/PlayButtonSmall';
import { ThumbnailButton } from '../ThumbnailButton/ThumbnailButton';

type Props = {
  iconSize?: number;
  text?: string;
  onClick?(): void;
};

export const ThumbnailPlayButton: FC<Props> = ({ iconSize, onClick, text }) => {
  return (
    <ThumbnailButton
      Icon={PlayButtonSmall}
      onClick={onClick}
      iconSize={iconSize}
      text={text}
    />
  );
};
