import React, { FC } from 'react';
import styles from './Header.scss';
import { ShareButton } from '../../../../common/components/ShareButton/ShareButton';
import { InfoButton } from '../../../../common/components/InfoButton/InfoButton';
import { TextButton } from '../../../../common/components/TextButton/TextButton';
import { useChannel } from '../../../../hooks/useChannel';
import { TextButtonPriority } from 'wix-ui-tpa';
import { useChannelAccessButton } from '../../../../hooks';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';

const useHeaderSettings = () => {
  const styles = useStyles();
  const showTitle = styles.get(stylesParams.showChannelTitle);
  const showChannelShare = styles.get(stylesParams.showChannelShare);
  const showChannelInfo = styles.get(stylesParams.showChannelInfo);
  return { showTitle, showChannelShare, showChannelInfo };
};

const ShareChannelButton: FC = () => <ShareButton />;

const ChannelInfoButton: FC = () => <InfoButton />;

const ChannelAccessButton: FC = () => {
  const channelAccessButton = useChannelAccessButton();
  if (!channelAccessButton) {
    return null;
  }
  return (
    <TextButton
      onClick={() => channelAccessButton.handler()}
      priority={TextButtonPriority.secondary}
    >
      {channelAccessButton.text}
    </TextButton>
  );
};

const ButtonWrapper: FC = ({ children }) => (
  <div className={styles.buttonWrapper}>{children}</div>
);

export const Header: FC = () => {
  const channel = useChannel();
  const channelAccessButton = useChannelAccessButton();

  const { showTitle, showChannelInfo, showChannelShare } = useHeaderSettings();

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{showTitle ? channel.title : null}</h2>
      <div className={styles.buttons}>
        {showChannelShare ? (
          <ButtonWrapper>
            <ShareChannelButton key="share-channel-button" />
          </ButtonWrapper>
        ) : null}
        {showChannelInfo ? (
          <ButtonWrapper>
            <ChannelInfoButton key="channel-info-button" />
          </ButtonWrapper>
        ) : null}
        {channelAccessButton ? (
          <ButtonWrapper>
            <ChannelAccessButton key="channel-access-button" />
          </ButtonWrapper>
        ) : null}
      </div>
    </div>
  );
};
