import React, { FC } from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa';
import { st, classes } from './Button.st.css';
import { useStyles } from '@wix/tpa-settings/react';
import classNames from 'classnames';
import stylesParams from '../../../../../stylesParams';
import { LAYOUT_STYLE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/layout-style-values';

export const Button: FC<ButtonProps> = ({ className, children, ...rest }) => {
  const styles = useStyles();

  return (
    <TPAButton
      className={classNames(
        st(classes.root, {
          rounded:
            styles.get(stylesParams.layoutStyle) === LAYOUT_STYLE_VALUES.ROUND,
        }),
        className,
      )}
      {...rest}
    >
      <div className={st(classes.content)}>{children}</div>
    </TPAButton>
  );
};
