import React, { FC, useCallback, useState } from 'react';
import { PlaybackContext } from '../../hooks/usePlayback';

export const PlaybackContextProvider: FC = ({ children }) => {
  const [playing, setPlaying] = useState(false);
  const play = useCallback(() => setPlaying(true), []);
  const pause = useCallback(() => setPlaying(false), []);

  return (
    <PlaybackContext.Provider
      value={{
        playing,
        play,
        pause,
      }}
    >
      {children}
    </PlaybackContext.Provider>
  );
};
