import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type GqlCancelPurchasePayload = {
  __typename?: 'CancelPurchasePayload';
  message: Scalars['String'];
  status: GqlMutationStatus;
};

export type GqlChannel = {
  __typename?: 'Channel';
  categories: Array<Scalars['String']>;
  content?: Maybe<GqlChannelMedia>;
  cover: GqlChannelCover;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  extendOptions?: Maybe<Scalars['JSON']>;
  featuredVideoId?: Maybe<Scalars['String']>;
  hasPaidVideos: Scalars['Boolean'];
  id: Scalars['ID'];
  metaSiteId: Scalars['ID'];
  play: GqlChannelPlayPayload;
  pricing: Array<GqlChannelPricing>;
  purchase?: Maybe<GqlPurchase>;
  purchases: GqlPurchasesPayload;
  source: GqlChannelSource;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploadedTrailer?: Maybe<GqlChannelMedia>;
  video?: Maybe<GqlVideo>;
  videos: GqlVideosPayload;
};


export type GqlChannelPlayArgs = {
  type?: InputMaybe<GqlChannelPlayType>;
};


export type GqlChannelPurchaseArgs = {
  filter: GqlChannelPurchaseFilter;
};


export type GqlChannelPurchasesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlChannelPurchasesFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};


export type GqlChannelVideoArgs = {
  filter: GqlVideoFilter;
};


export type GqlChannelVideosArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlVideosFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};

export type GqlChannelCover = {
  __typename?: 'ChannelCover';
  selected?: Maybe<Scalars['String']>;
};

export type GqlChannelFilter = {
  id: Scalars['ID'];
};

export type GqlChannelMedia = {
  __typename?: 'ChannelMedia';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  qualities?: Maybe<Array<Scalars['String']>>;
  revision?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  source: GqlChannelMediaSource;
  status: GqlMediaStatus;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum GqlChannelMediaSource {
  MediaPlatform = 'MEDIA_PLATFORM',
  PrivateMedia = 'PRIVATE_MEDIA',
  PrivateMediaV3 = 'PRIVATE_MEDIA_V3',
  Youtube = 'YOUTUBE'
}

export type GqlChannelPlay = {
  __typename?: 'ChannelPlay';
  /** @deprecated Field 'hls' is deprecated. Use 'url' instead */
  hls?: Maybe<Scalars['String']>;
  type?: Maybe<GqlChannelPlayType>;
  url?: Maybe<Scalars['String']>;
};

export type GqlChannelPlayPayload = {
  __typename?: 'ChannelPlayPayload';
  entity: GqlChannelPlay;
  message: Scalars['String'];
  status: GqlOperationStatus;
};

export enum GqlChannelPlayType {
  /** @deprecated Value `TRAILER` is deprecated. Use `UPLOADED_TRAILER` instead */
  Trailer = 'TRAILER',
  UploadedTrailer = 'UPLOADED_TRAILER'
}

export type GqlChannelPricing = {
  __typename?: 'ChannelPricing';
  currency: Scalars['String'];
  enabled: Scalars['Boolean'];
  period: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  required: Scalars['Boolean'];
  type: GqlChannelPricingType;
};

export enum GqlChannelPricingType {
  PricingPlan = 'PRICING_PLAN',
  Subscription = 'SUBSCRIPTION'
}

export type GqlChannelPurchaseFilter = {
  type: GqlChannelPurchaseType;
};

export enum GqlChannelPurchaseType {
  Subscription = 'SUBSCRIPTION'
}

export type GqlChannelPurchasesFilter = {
  status?: InputMaybe<GqlPurchaseStatus>;
  type?: InputMaybe<GqlChannelPurchaseType>;
};

export enum GqlChannelSource {
  Internal = 'INTERNAL',
  Youtube = 'YOUTUBE'
}

export type GqlChannelsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  query?: InputMaybe<Scalars['String']>;
};

export type GqlChannelsPayload = {
  __typename?: 'ChannelsPayload';
  cursor?: Maybe<Scalars['String']>;
  entities: Array<GqlChannel>;
  total: Scalars['Int'];
};

export type GqlCreatePurchaseOrderInput = {
  id: Scalars['ID'];
  redirect?: InputMaybe<GqlPurchaseOrderRedirectParams>;
  type: GqlPurchaseOrderType;
};

export enum GqlLiveIssuer {
  Mobile = 'MOBILE',
  Obs = 'OBS'
}

export enum GqlLiveProtocol {
  Rtmp = 'RTMP',
  Webrtc = 'WEBRTC'
}

export enum GqlLiveStatus {
  DvrProcessing = 'DVR_PROCESSING',
  Expired = 'EXPIRED',
  Finished = 'FINISHED',
  Pending = 'PENDING',
  Reconnecting = 'RECONNECTING',
  Scheduled = 'SCHEDULED',
  Streaming = 'STREAMING'
}

export enum GqlLiveType {
  Event = 'EVENT',
  Live = 'LIVE'
}

export enum GqlMediaStatus {
  Failed = 'FAILED',
  Initialized = 'INITIALIZED',
  InProgress = 'IN_PROGRESS',
  InQueue = 'IN_QUEUE',
  PreviewReady = 'PREVIEW_READY',
  Ready = 'READY'
}

export type GqlMutation = {
  __typename?: 'Mutation';
  purchase?: Maybe<GqlPurchaseMutations>;
};

export enum GqlMutationStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export enum GqlOperationStatus {
  Error = 'ERROR',
  Success = 'SUCCESS'
}

export type GqlPurchase = {
  __typename?: 'Purchase';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  metaSiteId: Scalars['ID'];
  orderId?: Maybe<Scalars['String']>;
  period: Scalars['Int'];
  price: Scalars['Float'];
  startedAt?: Maybe<Scalars['DateTime']>;
  status: GqlPurchaseStatus;
  type: GqlPurchaseType;
  updatedAt: Scalars['DateTime'];
};

export type GqlPurchaseFilter = {
  id: Scalars['ID'];
  type: GqlPurchaseType;
};

export type GqlPurchaseMutations = {
  __typename?: 'PurchaseMutations';
  cancel: GqlCancelPurchasePayload;
  order: GqlPurchaseOrderMutations;
};


export type GqlPurchaseMutationsCancelArgs = {
  filter: GqlPurchaseFilter;
};

export type GqlPurchaseOrder = {
  __typename?: 'PurchaseOrder';
  id: Scalars['ID'];
};

export type GqlPurchaseOrderMutations = {
  __typename?: 'PurchaseOrderMutations';
  create: GqlPurchaseOrderPayload;
};


export type GqlPurchaseOrderMutationsCreateArgs = {
  input: GqlCreatePurchaseOrderInput;
};

export type GqlPurchaseOrderPayload = {
  __typename?: 'PurchaseOrderPayload';
  entity?: Maybe<GqlPurchaseOrder>;
  message: Scalars['String'];
  status: GqlMutationStatus;
};

export type GqlPurchaseOrderRedirectParams = {
  params?: InputMaybe<Scalars['JSON']>;
  url: Scalars['String'];
};

export enum GqlPurchaseOrderType {
  Rent = 'RENT',
  Sale = 'SALE',
  Subscription = 'SUBSCRIPTION'
}

export enum GqlPurchaseStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Initialized = 'INITIALIZED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Suspended = 'SUSPENDED'
}

export enum GqlPurchaseType {
  Rent = 'RENT',
  Sale = 'SALE',
  Subscription = 'SUBSCRIPTION'
}

export type GqlPurchasesFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<GqlPurchaseStatus>;
  type?: InputMaybe<GqlPurchaseType>;
};

export type GqlPurchasesPayload = {
  __typename?: 'PurchasesPayload';
  cursor?: Maybe<Scalars['String']>;
  entities: Array<GqlPurchase>;
  total: Scalars['Int'];
};

export type GqlQuery = {
  __typename?: 'Query';
  channel?: Maybe<GqlChannel>;
  channels: GqlChannelsPayload;
  purchase?: Maybe<GqlPurchase>;
  purchases: GqlPurchasesPayload;
  video?: Maybe<GqlVideo>;
};


export type GqlQueryChannelArgs = {
  filter: GqlChannelFilter;
};


export type GqlQueryChannelsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlChannelsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};


export type GqlQueryPurchaseArgs = {
  filter?: InputMaybe<GqlPurchaseFilter>;
};


export type GqlQueryPurchasesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlPurchasesFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};


export type GqlQueryVideoArgs = {
  filter: GqlVideoFilter;
};

export enum GqlSortOrder {
  CustomAsc = 'CUSTOM_ASC',
  CustomDesc = 'CUSTOM_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedAsc = 'UPDATED_ASC',
  UpdatedDesc = 'UPDATED_DESC'
}

export type GqlVideo = {
  __typename?: 'Video';
  autogeneratedTrailer?: Maybe<GqlVideoMedia>;
  card?: Maybe<GqlVideoCard>;
  cards: GqlVideoCardsPayload;
  cast: Array<GqlVideoCast>;
  categories: Array<Scalars['String']>;
  /** @deprecated Field 'clip' is deprecated. Use 'defaultTrailer' instead */
  clip?: Maybe<GqlVideoMedia>;
  content?: Maybe<GqlVideoMedia>;
  cover: GqlVideoCover;
  createdAt: Scalars['DateTime'];
  crew: Array<GqlVideoCrew>;
  defaultTrailer?: Maybe<GqlVideoMedia>;
  description?: Maybe<Scalars['String']>;
  download: GqlVideoDownloadPayload;
  downloadOptions: GqlVideoDownloadOptions;
  embedOptions: GqlVideoEmbedOptions;
  extendOptions?: Maybe<Scalars['JSON']>;
  genre?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  live?: Maybe<GqlVideoLive>;
  metaSiteId: Scalars['ID'];
  play: GqlVideoPlayPayload;
  poster: GqlVideoPoster;
  pricing: Array<GqlVideoPricing>;
  publisher?: Maybe<Scalars['String']>;
  purchase?: Maybe<GqlPurchase>;
  purchases: GqlPurchasesPayload;
  source: GqlVideoSource;
  status: GqlVideoStatus;
  storyboard?: Maybe<GqlVideoMedia>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  /** @deprecated Field 'trailer' is deprecated. Use 'uploadedTrailer' instead */
  trailer?: Maybe<GqlVideoMedia>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uploadedTrailer?: Maybe<GqlVideoMedia>;
  viewerOptions: GqlVideoViewerOptions;
};


export type GqlVideoCardArgs = {
  filter: GqlVideoCardFilter;
};


export type GqlVideoCardsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlVideoCardsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};


export type GqlVideoDownloadArgs = {
  type?: InputMaybe<GqlVideoDownloadType>;
};


export type GqlVideoPlayArgs = {
  type?: InputMaybe<GqlVideoPlayType>;
};


export type GqlVideoPurchaseArgs = {
  filter: GqlVideoPurchaseFilter;
};


export type GqlVideoPurchasesArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlVideoPurchasesFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GqlSortOrder>;
};

export type GqlVideoCard = {
  __typename?: 'VideoCard';
  buttonText?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customOrder: Scalars['Float'];
  hideAt: Scalars['Int'];
  id: Scalars['ID'];
  metaSiteId: Scalars['ID'];
  position: GqlVideoCardPosition;
  showAt: Scalars['Int'];
  targetChannelId?: Maybe<Scalars['ID']>;
  targetExternalId?: Maybe<Scalars['String']>;
  targetExternalType?: Maybe<GqlVideoCardTargetExternalType>;
  targetExternalUrl?: Maybe<Scalars['String']>;
  targetVideoId?: Maybe<Scalars['ID']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: GqlVideoCardType;
  updatedAt: Scalars['DateTime'];
  videoId: Scalars['ID'];
};

export type GqlVideoCardFilter = {
  id: Scalars['ID'];
};

export enum GqlVideoCardPosition {
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT'
}

export enum GqlVideoCardTargetExternalType {
  Facebook = 'FACEBOOK',
  Twitter = 'TWITTER',
  Youtube = 'YOUTUBE'
}

export enum GqlVideoCardType {
  Channel = 'CHANNEL',
  Custom = 'CUSTOM',
  Product = 'PRODUCT',
  RecentVideo = 'RECENT_VIDEO',
  Social = 'SOCIAL',
  Video = 'VIDEO'
}

export type GqlVideoCardsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type GqlVideoCardsPayload = {
  __typename?: 'VideoCardsPayload';
  cursor?: Maybe<Scalars['String']>;
  entities: Array<GqlVideoCard>;
  total: Scalars['Int'];
};

export type GqlVideoCast = {
  __typename?: 'VideoCast';
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type GqlVideoCover = {
  __typename?: 'VideoCover';
  selected?: Maybe<Scalars['String']>;
};

export type GqlVideoCrew = {
  __typename?: 'VideoCrew';
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type GqlVideoDownload = {
  __typename?: 'VideoDownload';
  type?: Maybe<GqlVideoDownloadType>;
  url?: Maybe<Scalars['String']>;
};

export type GqlVideoDownloadOptions = {
  __typename?: 'VideoDownloadOptions';
  enabled: Scalars['Boolean'];
};

export type GqlVideoDownloadPayload = {
  __typename?: 'VideoDownloadPayload';
  entity: GqlVideoDownload;
  message: Scalars['String'];
  status: GqlOperationStatus;
};

export enum GqlVideoDownloadType {
  Content = 'CONTENT'
}

export type GqlVideoEmbedOptions = {
  __typename?: 'VideoEmbedOptions';
  enabled: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  logoVisibility: GqlVideoLogoVisibility;
  showInfo: Scalars['Boolean'];
  showLogo: Scalars['Boolean'];
  showShare: Scalars['Boolean'];
  showTitle: Scalars['Boolean'];
};

export type GqlVideoFilter = {
  id: Scalars['ID'];
};

export type GqlVideoLive = {
  __typename?: 'VideoLive';
  counterUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  duration?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  issuer?: Maybe<GqlLiveIssuer>;
  pingUrl?: Maybe<Scalars['String']>;
  protocol?: Maybe<GqlLiveProtocol>;
  size?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: GqlLiveStatus;
  streamerUrl?: Maybe<Scalars['String']>;
  type?: Maybe<GqlLiveType>;
};

export enum GqlVideoLogoVisibility {
  Always = 'ALWAYS',
  ControlBar = 'CONTROL_BAR'
}

export type GqlVideoMedia = {
  __typename?: 'VideoMedia';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  externalId?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  originalName?: Maybe<Scalars['String']>;
  qualities?: Maybe<Array<Scalars['String']>>;
  revision?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  source: GqlVideoMediaSource;
  status: GqlMediaStatus;
  thumbnailUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export enum GqlVideoMediaSource {
  Facebook = 'FACEBOOK',
  MediaPlatform = 'MEDIA_PLATFORM',
  PrivateMedia = 'PRIVATE_MEDIA',
  PrivateMediaV3 = 'PRIVATE_MEDIA_V3',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE'
}

export type GqlVideoPlay = {
  __typename?: 'VideoPlay';
  /** @deprecated Field 'hls' is deprecated. Use 'url' instead */
  hls?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  type?: Maybe<GqlVideoPlayType>;
  url?: Maybe<Scalars['String']>;
};

export type GqlVideoPlayPayload = {
  __typename?: 'VideoPlayPayload';
  entity: GqlVideoPlay;
  message: Scalars['String'];
  status: GqlOperationStatus;
};

export enum GqlVideoPlayType {
  AutogeneratedTrailer = 'AUTOGENERATED_TRAILER',
  /** @deprecated Value `CLIP` is deprecated. Use `DEFAULT_TRAILER` instead */
  Clip = 'CLIP',
  Content = 'CONTENT',
  DefaultTrailer = 'DEFAULT_TRAILER',
  Storyboard = 'STORYBOARD',
  /** @deprecated Value `TRAILER` is deprecated. Use `UPLOADED_TRAILER` instead */
  Trailer = 'TRAILER',
  UploadedTrailer = 'UPLOADED_TRAILER'
}

export type GqlVideoPoster = {
  __typename?: 'VideoPoster';
  selected?: Maybe<Scalars['String']>;
};

export type GqlVideoPricing = {
  __typename?: 'VideoPricing';
  currency: Scalars['String'];
  enabled: Scalars['Boolean'];
  period: Scalars['Int'];
  price: Scalars['Float'];
  type: GqlVideoPricingType;
};

export enum GqlVideoPricingType {
  Rent = 'RENT',
  Sale = 'SALE'
}

export type GqlVideoPurchaseFilter = {
  type: GqlVideoPurchaseType;
};

export enum GqlVideoPurchaseType {
  Rent = 'RENT',
  Sale = 'SALE'
}

export type GqlVideoPurchasesFilter = {
  status?: InputMaybe<GqlPurchaseStatus>;
  type?: InputMaybe<GqlVideoPurchaseType>;
};

export enum GqlVideoSource {
  Computer = 'COMPUTER',
  Dropbox = 'DROPBOX',
  Facebook = 'FACEBOOK',
  GoogleDrive = 'GOOGLE_DRIVE',
  Live = 'LIVE',
  Vimeo = 'VIMEO',
  Youtube = 'YOUTUBE'
}

export enum GqlVideoStatus {
  Visible = 'VISIBLE'
}

export enum GqlVideoTrailerType {
  Autogenerated = 'AUTOGENERATED',
  Default = 'DEFAULT',
  Uploaded = 'UPLOADED'
}

export type GqlVideoViewerOptions = {
  __typename?: 'VideoViewerOptions';
  isMatureContent: Scalars['Boolean'];
  signupRequired: Scalars['Boolean'];
  trailerEnabled: Scalars['Boolean'];
  trailerType: GqlVideoTrailerType;
  /** @deprecated Field `trailerUseUploaded` is deprecated. Use `trailerType` instead */
  trailerUseUploaded?: Maybe<Scalars['Boolean']>;
};

export type GqlVideosFilter = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  query?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

export type GqlVideosPayload = {
  __typename?: 'VideosPayload';
  cursor?: Maybe<Scalars['String']>;
  entities: Array<GqlVideo>;
  total: Scalars['Int'];
};

export type GqlChannelFragment = { __typename?: 'Channel', id: string, title: string, description?: string | undefined, tags: Array<string>, categories: Array<string>, cover: { __typename?: 'ChannelCover', selected?: string | undefined }, pricing: Array<{ __typename?: 'ChannelPricing', type: GqlChannelPricingType, enabled: boolean, price?: number | undefined, currency: string, period: number, required: boolean }>, purchases: { __typename?: 'PurchasesPayload', entities: Array<{ __typename?: 'Purchase', type: GqlPurchaseType, status: GqlPurchaseStatus }> } };

export type GqlChannelPricingFragment = { __typename?: 'ChannelPricing', type: GqlChannelPricingType, enabled: boolean, price?: number | undefined, currency: string, period: number, required: boolean };

export type GqlPurchaseFragment = { __typename?: 'Purchase', type: GqlPurchaseType, status: GqlPurchaseStatus };

export type GqlVideoFragment = { __typename?: 'Video', id: string, title: string, source: GqlVideoSource, cover: { __typename?: 'VideoCover', selected?: string | undefined }, play: { __typename?: 'VideoPlayPayload', entity: { __typename?: 'VideoPlay', url?: string | undefined } }, content?: { __typename?: 'VideoMedia', duration?: number | undefined } | undefined, pricing: Array<{ __typename?: 'VideoPricing', type: GqlVideoPricingType, price: number, currency: string, period: number, enabled: boolean }>, defaultTrailer?: { __typename?: 'VideoMedia', externalUrl?: string | undefined } | undefined, viewerOptions: { __typename?: 'VideoViewerOptions', trailerEnabled: boolean, trailerType: GqlVideoTrailerType, signupRequired: boolean, isMatureContent: boolean }, live?: { __typename?: 'VideoLive', status: GqlLiveStatus } | undefined };

export type GqlVideoPricingFragment = { __typename?: 'VideoPricing', type: GqlVideoPricingType, price: number, currency: string, period: number, enabled: boolean };

export type GqlVideoViewerOptionsFragment = { __typename?: 'VideoViewerOptions', trailerEnabled: boolean, trailerType: GqlVideoTrailerType, signupRequired: boolean, isMatureContent: boolean };

export type GqlGetChannelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GqlGetChannelQuery = { __typename?: 'Query', channel?: { __typename?: 'Channel', id: string, title: string, description?: string | undefined, tags: Array<string>, categories: Array<string>, cover: { __typename?: 'ChannelCover', selected?: string | undefined }, pricing: Array<{ __typename?: 'ChannelPricing', type: GqlChannelPricingType, enabled: boolean, price?: number | undefined, currency: string, period: number, required: boolean }>, purchases: { __typename?: 'PurchasesPayload', entities: Array<{ __typename?: 'Purchase', type: GqlPurchaseType, status: GqlPurchaseStatus }> } } | undefined };

export type GqlGetChannelVideosQueryVariables = Exact<{
  id: Scalars['ID'];
  cursor?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GqlVideosFilter>;
}>;


export type GqlGetChannelVideosQuery = { __typename?: 'Query', channel?: { __typename?: 'Channel', videos: { __typename?: 'VideosPayload', cursor?: string | undefined, entities: Array<{ __typename?: 'Video', id: string, title: string, source: GqlVideoSource, cover: { __typename?: 'VideoCover', selected?: string | undefined }, play: { __typename?: 'VideoPlayPayload', entity: { __typename?: 'VideoPlay', url?: string | undefined } }, content?: { __typename?: 'VideoMedia', duration?: number | undefined } | undefined, pricing: Array<{ __typename?: 'VideoPricing', type: GqlVideoPricingType, price: number, currency: string, period: number, enabled: boolean }>, defaultTrailer?: { __typename?: 'VideoMedia', externalUrl?: string | undefined } | undefined, viewerOptions: { __typename?: 'VideoViewerOptions', trailerEnabled: boolean, trailerType: GqlVideoTrailerType, signupRequired: boolean, isMatureContent: boolean }, live?: { __typename?: 'VideoLive', status: GqlLiveStatus } | undefined }> } } | undefined };

export type GqlGetVideoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GqlGetVideoQuery = { __typename?: 'Query', video?: { __typename?: 'Video', id: string, title: string, source: GqlVideoSource, cover: { __typename?: 'VideoCover', selected?: string | undefined }, play: { __typename?: 'VideoPlayPayload', entity: { __typename?: 'VideoPlay', url?: string | undefined } }, content?: { __typename?: 'VideoMedia', duration?: number | undefined } | undefined, pricing: Array<{ __typename?: 'VideoPricing', type: GqlVideoPricingType, price: number, currency: string, period: number, enabled: boolean }>, defaultTrailer?: { __typename?: 'VideoMedia', externalUrl?: string | undefined } | undefined, viewerOptions: { __typename?: 'VideoViewerOptions', trailerEnabled: boolean, trailerType: GqlVideoTrailerType, signupRequired: boolean, isMatureContent: boolean }, live?: { __typename?: 'VideoLive', status: GqlLiveStatus } | undefined } | undefined };

export const ChannelPricingFragmentDoc = gql`
    fragment ChannelPricing on ChannelPricing {
  type
  enabled
  price
  currency
  period
  required
}
    `;
export const PurchaseFragmentDoc = gql`
    fragment Purchase on Purchase {
  type
  status
}
    `;
export const ChannelFragmentDoc = gql`
    fragment Channel on Channel {
  id
  title
  description
  tags
  categories
  cover {
    selected
  }
  pricing {
    ...ChannelPricing
  }
  purchases {
    entities {
      ...Purchase
    }
  }
}
    ${ChannelPricingFragmentDoc}
${PurchaseFragmentDoc}`;
export const VideoPricingFragmentDoc = gql`
    fragment VideoPricing on VideoPricing {
  type
  price
  currency
  period
  enabled
}
    `;
export const VideoViewerOptionsFragmentDoc = gql`
    fragment VideoViewerOptions on VideoViewerOptions {
  trailerEnabled
  trailerType
  signupRequired
  isMatureContent
}
    `;
export const VideoFragmentDoc = gql`
    fragment Video on Video {
  id
  title
  source
  cover {
    selected
  }
  play {
    entity {
      url
    }
  }
  content {
    duration
  }
  pricing {
    ...VideoPricing
  }
  defaultTrailer {
    externalUrl
  }
  viewerOptions {
    ...VideoViewerOptions
  }
  live {
    status
  }
}
    ${VideoPricingFragmentDoc}
${VideoViewerOptionsFragmentDoc}`;
export const GetChannelDocument = gql`
    query GetChannel($id: ID!) {
  channel(filter: {id: $id}) {
    ...Channel
  }
}
    ${ChannelFragmentDoc}`;

/**
 * __useGetChannelQuery__
 *
 * To run a query within a React component, call `useGetChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChannelQuery(baseOptions: Apollo.QueryHookOptions<GqlGetChannelQuery, GqlGetChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetChannelQuery, GqlGetChannelQueryVariables>(GetChannelDocument, options);
      }
export function useGetChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetChannelQuery, GqlGetChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetChannelQuery, GqlGetChannelQueryVariables>(GetChannelDocument, options);
        }
export type GetChannelQueryHookResult = ReturnType<typeof useGetChannelQuery>;
export type GetChannelLazyQueryHookResult = ReturnType<typeof useGetChannelLazyQuery>;
export type GetChannelQueryResult = Apollo.QueryResult<GqlGetChannelQuery, GqlGetChannelQueryVariables>;
export const GetChannelVideosDocument = gql`
    query GetChannelVideos($id: ID!, $cursor: String, $filter: VideosFilter) {
  channel(filter: {id: $id}) {
    videos(filter: $filter, limit: 20, cursor: $cursor) {
      cursor
      entities {
        ...Video
      }
    }
  }
}
    ${VideoFragmentDoc}`;

/**
 * __useGetChannelVideosQuery__
 *
 * To run a query within a React component, call `useGetChannelVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetChannelVideosQuery(baseOptions: Apollo.QueryHookOptions<GqlGetChannelVideosQuery, GqlGetChannelVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetChannelVideosQuery, GqlGetChannelVideosQueryVariables>(GetChannelVideosDocument, options);
      }
export function useGetChannelVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetChannelVideosQuery, GqlGetChannelVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetChannelVideosQuery, GqlGetChannelVideosQueryVariables>(GetChannelVideosDocument, options);
        }
export type GetChannelVideosQueryHookResult = ReturnType<typeof useGetChannelVideosQuery>;
export type GetChannelVideosLazyQueryHookResult = ReturnType<typeof useGetChannelVideosLazyQuery>;
export type GetChannelVideosQueryResult = Apollo.QueryResult<GqlGetChannelVideosQuery, GqlGetChannelVideosQueryVariables>;
export const GetVideoDocument = gql`
    query GetVideo($id: ID!) {
  video(filter: {id: $id}) {
    ...Video
  }
}
    ${VideoFragmentDoc}`;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoQuery(baseOptions: Apollo.QueryHookOptions<GqlGetVideoQuery, GqlGetVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GqlGetVideoQuery, GqlGetVideoQueryVariables>(GetVideoDocument, options);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GqlGetVideoQuery, GqlGetVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GqlGetVideoQuery, GqlGetVideoQueryVariables>(GetVideoDocument, options);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GqlGetVideoQuery, GqlGetVideoQueryVariables>;