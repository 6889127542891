import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { PlaybackComponentProps } from '../../Player.types';
import Player from '@vimeo/player';
import s from './Vimeo.scss';

export const Vimeo: FC<PlaybackComponentProps> = ({ src, playing }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [player, setPlayer] = useState<Player>();

  useEffect(() => {
    const iframe = ref.current;
    if (iframe) {
      setPlayer(new Player(iframe));
    }
  }, [ref]);

  const play = useCallback(() => {
    if (player) {
      player.setVolume(0.5).then(() => player.play());
    }
  }, [player]);

  useEffect(() => {
    if (playing) {
      play();
    }
  }, [playing, play]);

  return (
    <iframe
      ref={ref}
      title="Vimeo"
      className={s.vimeo}
      src={src}
      frameBorder="0"
      allowFullScreen
      allow="autoplay; encrypted-media"
    />
  );
};
