import React, { FC } from 'react';
import { Button } from '../Button/Button';
import PlayFillSmall from 'wix-ui-icons-common/on-stage/PlayFillSmall';
import { usePlayback } from '../../../hooks';

export const PlayButton: FC = ({ children }) => {
  const { play } = usePlayback();

  return (
    <Button onClick={play} data-hook="play-button">
      <PlayFillSmall style={{ marginRight: 10 }} />
      <span>{children}</span>
    </Button>
  );
};
