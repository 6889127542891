import { GqlChannelPricingType } from '../apollo/generated/graphql';
import { useChannel } from './useChannel';

export const useChannelPricing = () => {
  const channel = useChannel();

  let isPricingPlanChannel = false,
    channelSubscription,
    purchaseOrRentDisabled = false;

  const { pricing } = channel;

  for (const p of pricing) {
    const { enabled, type, price, currency, required } = p;

    if (enabled) {
      purchaseOrRentDisabled = required;

      if (type === GqlChannelPricingType.PricingPlan) {
        isPricingPlanChannel = true;
      }

      if (type === GqlChannelPricingType.Subscription && price) {
        channelSubscription = {
          price,
          currency,
        };
      }
    }
  }

  return {
    isPricingPlanChannel,
    isFreeChannel: !channelSubscription && !isPricingPlanChannel,
    channelSubscription,
    purchaseOrRentDisabled,
  };
};
