import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';
import { DesktopLayout } from './Widget/new/types/enums';

export type IStylesParams = {
  // Colors
  backgroundColor: StyleParamType.Color;
  textColor: StyleParamType.Color;
  buttonsAndLinksColor: StyleParamType.Color;
  mainButtonColor: StyleParamType.Color;
  mainButtonTextColor: StyleParamType.Color;
  mobileMenuTextColor: StyleParamType.Color;
  mobileMenuBackgroundColor: StyleParamType.Color;
  thumbnailButtonsColor: StyleParamType.Color;
  mobileButtonsTextColor: StyleParamType.Color;
  mobileButtonsBackgroundColor: StyleParamType.Color;
  dividersAndArrowsColor: StyleParamType.Color;
  emptyPlayIconEditor: StyleParamType.Color;
  emptyPlayIconSite: StyleParamType.Color;
  emptyPlayerBgEditor: StyleParamType.Color;
  emptyPlayerBgSite: StyleParamType.Color;
  compactMenuBackgroundColor: StyleParamType.Color;
  compactMenuActionsColor: StyleParamType.Color;
  thumbnailOverlayColor: StyleParamType.Color;

  // Fonts
  textFont: StyleParamType.Font;
  titleFont: StyleParamType.Font;
  videoInfoFont: StyleParamType.Font;
  language: StyleParamType.Font;

  // Numbers
  channelLayout: StyleParamType.Number;
  compactShowVideoListType: StyleParamType.Number;
  containerMargins: StyleParamType.Number;
  expandedTheme: StyleParamType.Number;
  layoutStyle: StyleParamType.Number;
  loadMoreButtonType: StyleParamType.Number;
  mainActionStyleType: StyleParamType.Number;
  mainItemOverlayOpacity: StyleParamType.Number;
  mainItemTitlePosition: StyleParamType.Number;
  mainItemType: StyleParamType.Number;
  navigationArrowsBehavior: StyleParamType.Number;
  navigationArrowsPosition: StyleParamType.Number;
  numberOfRows: StyleParamType.Number;
  playType: StyleParamType.Number;
  settingsVersion: StyleParamType.Number;
  showExpandedVideoListInfo: StyleParamType.Number;
  showInfoAction: StyleParamType.Number;
  showMediaType: StyleParamType.Number;
  sliderHeight: StyleParamType.Number;
  textAlignment: StyleParamType.Number;
  thumbnailButtonsBehavior: StyleParamType.Number;
  thumbnailOverlayOpacity: StyleParamType.Number;
  thumbnailSpacing: StyleParamType.Number;
  videoEndOption: StyleParamType.Number;
  videoInfoPosition: StyleParamType.Number;
  videoListVisibility: StyleParamType.Number;
  videosInRow: StyleParamType.Number;

  // Booleans
  showTitleForMainItem: StyleParamType.Boolean;
  showPublisherForMainItem: StyleParamType.Boolean;
  showChannelTitle: StyleParamType.Boolean;
  showChannelShare: StyleParamType.Boolean;
  showChannelInfo: StyleParamType.Boolean;
  showChannelCategories: StyleParamType.Boolean;
  showSearch: StyleParamType.Boolean;
  showChannelTags: StyleParamType.Boolean;
  showVideoTitle: StyleParamType.Boolean;
  showVideoInfo: StyleParamType.Boolean;
  enableVideosSharing: StyleParamType.Boolean;
  autoPlay: StyleParamType.Boolean;
  showSignIn: StyleParamType.Boolean;
  showVideoTitleInList: StyleParamType.Boolean;
  showVideoPublisherInList: StyleParamType.Boolean;
};

export const stylesParams = createStylesParams<IStylesParams>({
  // Colors
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonsAndLinksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  thumbnailButtonsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mobileButtonsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dividersAndArrowsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  emptyPlayIconEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  emptyPlayIconSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  emptyPlayerBgEditor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  emptyPlayerBgSite: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  compactMenuBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  compactMenuActionsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  thumbnailOverlayColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },

  // Fonts
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Title'),
  },
  videoInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-S'),
  },
  language: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      value: 'en',
      fontStyleParam: false, // not sure what this is
    }),
  },

  // Numbers
  channelLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => DesktopLayout.Classic,
  },
  compactShowVideoListType: {
    type: StyleParamType.Number,
  },
  containerMargins: {
    type: StyleParamType.Number,
  },
  expandedTheme: {
    type: StyleParamType.Number,
  },
  layoutStyle: {
    type: StyleParamType.Number,
  },
  loadMoreButtonType: {
    type: StyleParamType.Number,
  },
  mainActionStyleType: {
    type: StyleParamType.Number,
  },
  mainItemOverlayOpacity: {
    type: StyleParamType.Number,
  },
  mainItemTitlePosition: {
    type: StyleParamType.Number,
  },
  mainItemType: {
    type: StyleParamType.Number,
  },
  navigationArrowsBehavior: {
    type: StyleParamType.Number,
  },
  navigationArrowsPosition: {
    type: StyleParamType.Number,
  },
  numberOfRows: {
    type: StyleParamType.Number,
  },
  playType: {
    type: StyleParamType.Number,
  },
  settingsVersion: {
    type: StyleParamType.Number,
  },
  showExpandedVideoListInfo: {
    type: StyleParamType.Number,
  },
  showInfoAction: {
    type: StyleParamType.Number,
  },
  showMediaType: {
    type: StyleParamType.Number,
  },
  sliderHeight: {
    type: StyleParamType.Number,
  },
  textAlignment: {
    type: StyleParamType.Number,
  },
  thumbnailButtonsBehavior: {
    type: StyleParamType.Number,
  },
  thumbnailOverlayOpacity: {
    type: StyleParamType.Number,
  },
  thumbnailSpacing: {
    type: StyleParamType.Number,
  },
  videoEndOption: {
    type: StyleParamType.Number,
  },
  videoInfoPosition: {
    type: StyleParamType.Number,
  },
  videoListVisibility: {
    type: StyleParamType.Number,
  },
  videosInRow: {
    type: StyleParamType.Number,
  },

  // Booleans
  showTitleForMainItem: {
    type: StyleParamType.Boolean,
  },
  showPublisherForMainItem: {
    type: StyleParamType.Boolean,
  },
  showChannelTitle: {
    type: StyleParamType.Boolean,
  },
  showChannelShare: {
    type: StyleParamType.Boolean,
  },
  showChannelInfo: {
    type: StyleParamType.Boolean,
  },
  showChannelCategories: {
    type: StyleParamType.Boolean,
  },
  showSearch: {
    type: StyleParamType.Boolean,
  },
  showChannelTags: {
    type: StyleParamType.Boolean,
  },
  showVideoTitle: {
    type: StyleParamType.Boolean,
  },
  showVideoInfo: {
    type: StyleParamType.Boolean,
  },
  enableVideosSharing: {
    type: StyleParamType.Boolean,
  },
  autoPlay: {
    type: StyleParamType.Boolean,
  },
  showSignIn: {
    type: StyleParamType.Boolean,
  },
  showVideoTitleInList: {
    type: StyleParamType.Boolean,
  },
  showVideoPublisherInList: {
    type: StyleParamType.Boolean,
  },
});
export default stylesParams;
