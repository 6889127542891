import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactPlayable } from 'react-playable';
import HLSAdapter from 'playable/dist/src/adapters/hls';
import { MediaStreamType } from 'playable/dist/src/constants';
import { PlaybackComponentProps } from '../Player.types';
import { IPlayerInstance, VIDEO_EVENTS, ENGINE_STATES } from 'playable';

const config = {
  playbackAdapters: [HLSAdapter],
};

export const Playable: FC<PlaybackComponentProps> = ({
  src,
  title,
  playing,
  onEnd,
}) => {
  const [player, setPlayer] = useState<IPlayerInstance>();

  const handleStateChanged = useCallback(
    ({ nextState }: { prevState: ENGINE_STATES; nextState: ENGINE_STATES }) => {
      if (nextState === ENGINE_STATES.ENDED) {
        onEnd();
      }
    },
    [onEnd],
  );

  const handleInit = useCallback(
    (instance: IPlayerInstance) => {
      setPlayer(instance);

      if (instance.on) {
        instance.on(VIDEO_EVENTS.STATE_CHANGED, handleStateChanged);
      }
    },
    [handleStateChanged],
  );

  const play = useCallback(() => {
    if (player?.play) {
      player?.play();
    }
  }, [player]);

  useEffect(() => {
    if (playing) {
      play();
    }
  }, [playing, play]);

  return playing ? (
    <ReactPlayable
      key={src}
      src={{
        type: MediaStreamType.HLS,
        url: src,
      }}
      fillAllSpace
      config={config}
      title={title}
      onInit={handleInit}
    />
  ) : null;
};
