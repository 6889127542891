import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import s from './Classic.scss';
import { Player } from '../../../common/components/Player/Player';
import { VideoAspectRatio } from '../../../common/components/VideoAspectRatio/VideoAspectRatio';
import { Header } from './Header/Header';
import { Videos } from './Videos/Videos';
import { usePlayback, useSelectedVideo, useVideoSource } from '../../../hooks';
import { Search } from './Search/Search';
import { Categories } from './Categories/Categories';
import { NoResult } from './Search/NoResult/NoResult';
import { useVideosFilter } from '../../../hooks/useVideosFilter';
import { useChannelVideos } from '../../../hooks/useChannelVideos';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../stylesParams';
import { ChannelOverlay } from './ChannelOverlay/ChannelOverlay';
import { VideoOverlay } from './VideoOverlay/VideoOverlay';
import { ComingSoon } from './ComingSoon/ComingSoon';
import { SelectedVideoProvider } from '../../../common/providers/SelectedVideoProvider';
import { useChannelContext } from '../../../hooks/useChannel';

const useViewSettings = () => {
  const styles = useStyles();
  const showChannelCategories = styles.get(stylesParams.showChannelCategories);
  const showSearch = styles.get(stylesParams.showSearch);
  return { showChannelCategories, showSearch };
};

const OverlayWithPlayer: FC = () => {
  const { selectedVideo, selectedVideoChanged } = useSelectedVideo();
  const { isStreamingLive } = useVideoSource(selectedVideo);
  const [showChannelOverlay, setShowChannelOverlay] = useState(
    !isStreamingLive,
  );
  const { playing } = usePlayback();

  useEffect(() => {
    if (playing || selectedVideoChanged) {
      setShowChannelOverlay(false);
    }
  }, [playing, selectedVideoChanged]);

  return (
    <VideoAspectRatio>
      <Player video={selectedVideo} key={selectedVideo.id} />
      {playing ? null : showChannelOverlay ? (
        <ChannelOverlay />
      ) : (
        <VideoOverlay />
      )}
    </VideoAspectRatio>
  );
};

const Content: FC = () => {
  const { videos, videosSearching } = useChannelVideos();
  const { isEmptyFilter } = useVideosFilter();
  const { showChannelCategories, showSearch } = useViewSettings();
  const showNoResult = useMemo(
    () => !isEmptyFilter && !videos?.length && !videosSearching,
    [isEmptyFilter, videos, videosSearching],
  );

  return (
    <SelectedVideoProvider>
      <OverlayWithPlayer />
      <div className={s.footer}>
        {showSearch ? (
          <div className={s.search}>
            <Search />
          </div>
        ) : null}
        {showChannelCategories ? (
          <div className={s.categories}>
            <Categories />
          </div>
        ) : null}
        <div className={s.videos}>
          <Videos />
          {showNoResult && (
            <div className={s.noSearchResult}>
              <NoResult />
            </div>
          )}
        </div>
      </div>
    </SelectedVideoProvider>
  );
};

const useShouldRenderComingSoon = () => {
  const channel = useChannelContext();
  const { videos } = useChannelVideos();
  const { isEmptyFilter } = useVideosFilter();

  const shouldRenderComingSoon =
    !channel || (isEmptyFilter && videos.length === 0);

  return {
    hasChannel: Boolean(channel),
    shouldRenderComingSoon,
  };
};

export const Classic: FC = () => {
  const { shouldRenderComingSoon, hasChannel } = useShouldRenderComingSoon();

  return (
    <div className={classNames(s.root)}>
      {hasChannel ? <Header /> : null}
      {shouldRenderComingSoon ? <ComingSoon /> : <Content />}
    </div>
  );
};
