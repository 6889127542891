import React, { FC } from 'react';
import s from './ThumbnailButton.scss';

type Props = {
  Icon: FC<React.SVGAttributes<SVGElement>>;
  onClick?(): void;
  iconSize?: number;
  text?: string;
};

export const ThumbnailButton: FC<Props> = ({
  Icon,
  onClick,
  iconSize = 40,
  text,
}) => {
  return (
    <button className={s.root} onClick={onClick}>
      <Icon width={`${iconSize}px`} height={`${iconSize}px`} />
      {text && <span>{text}</span>}
    </button>
  );
};
